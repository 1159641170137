/* eslint-disable react/button-has-type */
import React from 'react';
import s from './FiltersFooter.scss';
import classNames from 'classnames';

import {FiltersSubmitButton} from '../FiltersSubmitButton/FiltersSubmitButton';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';

export interface IFiltersFooterProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  shouldShowClearFiltersButton: boolean;
  closeMobileFiltersModal: Function;
  clearFilters: Function;
}

class FiltersFooterComp extends React.Component<IFiltersFooterProps> {
  private clearFiltersButtonClicked() {
    this.props.clearFilters();
  }

  private renderClearFiltersButton() {
    const {
      globals: {hasSelectedFilters, shouldShowMobile},
    } = this.props;

    return (
      <button
        data-hook="clear-filters-button"
        onClick={() => this.clearFiltersButtonClicked()}
        className={classNames(s.clearFiltersButton, {[s.disabled]: !hasSelectedFilters})}>
        {this.props.t('clearFiltersButtonText')}
        {!shouldShowMobile && (
          <span className={s.clearFiltersX} data-hook="clear-filters-button-x">
            X
          </span>
        )}
      </button>
    );
  }

  public render() {
    const {shouldShowMobile} = this.props.globals;
    const {shouldShowClearFiltersButton, closeMobileFiltersModal} = this.props;

    return (
      <div className={s.actions}>
        {shouldShowClearFiltersButton && this.renderClearFiltersButton()}
        {shouldShowMobile && <FiltersSubmitButton closeMobileFiltersModal={closeMobileFiltersModal} />}
      </div>
    );
  }
}

export const FiltersFooter = withGlobals(withTranslations()(FiltersFooterComp));
