import {MobileSort} from './MobileSort/MobileSort';
import React from 'react';
import {DataHook} from '../../../category/components/CategoriesApp/CategoriesApp';
import {MobileFilters} from './MobileFilters/MobileFilters';
import {IGlobals} from '../../../gallery/galleryGlobalStrategy';
import {BrowseMobile} from '../../../gallery/components/BrowseMobile/BrowseMobile';
import s from './MobileFiltersAndSort.scss';

export const MobileFiltersAndSort = ({filterModels, shouldShowSort, isCategoryPage}: IGlobals) => {
  const shouldShowFilters = filterModels.length > 0;

  const getFiltersView = () => {
    return (
      <div className={s.mobileFiltersAndSort} data-hook={DataHook.MobileContainer}>
        {shouldShowFilters && <MobileFilters />}
        {shouldShowSort && <MobileSort areFiltersEnabled={shouldShowFilters} />}
      </div>
    );
  };

  const getSplitView = () => {
    return (
      <>
        {shouldShowFilters && <BrowseMobile />}
        <div className={s.restOfFilters}>{getFiltersView()}</div>
      </>
    );
  };

  return isCategoryPage ? getSplitView() : getFiltersView();
};
